.home-wrapper {
  height: calc(100% - 84px);
  .scroll-container {
    height: calc(100% - 185px);
    .swiper-slide {
      display: flex;
      justify-content: center;
    }
  }
}
