.poppins {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.desktop-view {
  width: auto;
}

.desktop-terms {
  width: auto;
}
