.tab-navigation {
    display: flex;
  }
  
  .tab-item {
    text-decoration: none;
    padding: 10px;
    margin-right: 10px;
    color: #555;
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-bottom 0.3s;
  }
  
  .tab-item:hover {
    color: #007bff;
  }
  
  .tab-item.active {
    color: #007bff;
    border-bottom: 2px solid #007bff;
  }
  