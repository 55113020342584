$primary-color: #48505E;
$secondary-color: #FAFAFA;
$gap: 8px;
$marginLeft: 17px;
$marginTop: 15%;
$saveButtonLeft: 380px;

.card-list {
  display: flex;
  flex-wrap: wrap;
  gap: $gap;
  margin-left: $marginLeft;
  margin-top: $marginTop;
  width: 90%;
  max-height: 70vh;
  overflow: auto;
}

.heading {
  font-size: 16px;
  text-align: center;
  color: $primary-color;
  margin-top: 20px;
}

.icon {
  position: absolute;
  left: $marginLeft;
  top: 20px;
  background-color: $secondary-color;
  padding: 8px;
  border-radius: 5px;
}

.save {
  position: absolute;
  left: $saveButtonLeft;
  top: 20px;
  transform: translateX(-100%);
  background-color: $secondary-color;
  padding: 8px;
  border-radius: 5px;
}

.modalStyle {
  position: absolute;
  width: 98%;
  height: 88%;
  top: 5%;
  left: 2%;
  background-color: white
}

.iconStyle {
  background-color: #FAFAFA;
  padding: 10px;
  border: 1px;
  border-radius: 5px
}

.commentBoxStyle {
  display: flex;
  width: 80%;
  padding: 5px 24px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--Primary-Gray-gray-50, #F0F1F3);
  background: var(--Default-White, #FFF);
  font-size: 14px;
  color: #48505E
}

.cmBox {
  display: flex;
  align-items: center;
}

.img {
  width: 65px;
  height: 65px;
  margin-left: '5px';
}