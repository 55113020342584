.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
  }
  
  .card-image {
    width: 100%;
    height: 100px;
    padding: 5px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .card-content {
    padding-left: 5px;
  }
  
  .card-title {
    color: var(--Primary-Gray-gray-700, #48505E);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
  }
  
  .card-description {
    color: var(--Primary-Gray-gray-400, #858D9D);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }
  