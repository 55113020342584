.reward-detail {
  .reward-icon-wrapper {
    svg {
      path {
        fill: #384bca;
      }
    }
  }
  .redeem-container {
    width: 343px;
  }
  .reward-detail-wrapper {
    height: calc(100% - 84px);
    .scroll-container {
      height: calc(100% - 68px);
    }
  }
  .redeem-btn-wrapper {
    background: #fff;
    box-shadow: 0px -3px 6px 0px rgba(209, 209, 209, 0.1),
      0px -12px 12px 0px rgba(209, 209, 209, 0.09),
      0px -26px 16px 0px rgba(209, 209, 209, 0.05),
      0px -46px 19px 0px rgba(209, 209, 209, 0.01),
      0px -72px 20px 0px rgba(209, 209, 209, 0);
    .redeem-btn {
      background: linear-gradient(213deg, #384bca 19.49%, #7b8cff 194.01%);
    }
  }
}
