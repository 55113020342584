.reward-wrapper {
  height: calc(100% - 84px);
  .scroll-container {
    height: calc(100% - 180px);
  }
  .total-point {
    display: flex;
    .point-total {
      h3,
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
