button.active {
  span {
    color: #384bca;
    font-weight: 600;
  }
  svg {
    path {
      fill: #384bca;
    }
  }
}
