.cardStyle {
    width: 387px;
    height: 259px;
    border: 1px solid #F0F1F3;
    border-radius: 19px;
    padding: 9px
  };
  
.imageSectionStyle {
    height: 139px;
    border-radius: 7px;
    overflow: hidden;
    width: 100%;
    padding: 5px;
    object-fit: cover;
  };